import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EncryptDecryptService } from "../shared/services/encryption.service";
import { SkanrayToastService } from "../shared/services/common/skanray-toast.service";
import { LoginService } from "../shared/services/login.service";
import { Observer } from "rxjs";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  public show: boolean = false;
  public forgotPasswordForm: FormGroup;
  public resetPasswordForm: FormGroup;
  isResetPassword = false;
  newPasswordShow = false;
  id: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private encryptDecryptService: EncryptDecryptService,
    private activatedRoute: ActivatedRoute,
    private loginService:LoginService,
    private toastService: SkanrayToastService
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required]],
      // password: ["adAd96", Validators.required],
    });

    this.resetPasswordForm = this.fb.group({
      newPassword: [null, [Validators.required]],
      confirmPassword: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.id = this.activatedRoute?.snapshot?.params["id"];
    console.log("this.id.................", this.id);
    this.isResetPassword = this.id ? true : false;
  }

  showPassword() {
    this.show = !this.show;
  }

  showNewPassword() {
    this.newPasswordShow = !this.newPasswordShow;
  }

  submit() {
    if(this.forgotPasswordForm.valid)
    this.forgotPassword();
  }

  resetFormSubmit() {
    const newPassword = this.resetPasswordForm?.get("newPassword")?.value;
    const confirmPassword =
      this.resetPasswordForm?.get("confirmPassword")?.value;
    const setFormData = {
      token: this.id,
      password: newPassword,
    };
    if (newPassword === confirmPassword) {
      this.resetPassword(setFormData)
      console.log("password reset successfull");
    } else console.log("password reset NOT successfull");
  }

  forgotPassword(){
    this.loginService.forgotPasswordAPI(this.forgotPasswordForm.value).subscribe({
      
      next: (response: any) => {
        if (response?.status === 201) {
          this.toasterWarning(response);
          this.router.navigate(["/auth/login"]);
        }
        console.log("Data sent successfully:", response);
      },
      error: (error: any) => {
        this.toasterWarning(error);
        console.error("Error sending data:", error);
      },
      complete: () => {
        // This block will be executed when the observable completes (optional)
        console.log("Observable completed");
      },
    } as Observer<any>);
  }

  resetPassword(setFormData: any){
    this.loginService.resetPasswordAPI(setFormData).subscribe({
      
      next: (response: any) => {
        if (response?.status === 201) {
          this.toasterWarning(response);
          this.router.navigate(["/auth/login"]);
        }
        console.log("Data sent successfully:", response);
      },
      error: (error: any) => {
        this.toasterWarning(error);
        console.error("Error sending data:", error);
      },
      complete: () => {
        // This block will be executed when the observable completes (optional)
        console.log("Observable completed");
      },
    } as Observer<any>);
  }

  toasterWarning(response: any) {
    this.toastService.showToaster(response);
  }
}
