import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanVistService } from '../../services/plan-visit-demo/plan-vist.service';
import { SkanrayToastService } from '../../services/common/skanray-toast.service';
import { Observer } from 'rxjs';
import { CustomerEnquiryService } from '../../services/manage-web-enquiry/customerEnquiry.service';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit{
  feedBackForm: FormGroup;
  convertToLeadForm: FormGroup;
  statusDropDownList:any[]=[
    {name:'COMPLETED'},
    {name:'POSTPONED'},
    {name:'CANCELLED'}
  ]
  userListDropDown: any[] = [];
  @Input() isVisit: boolean = false;
  @Input() convertToLeadID: string = "";
  @Input() cityId: string = "";
  @Input() vistData: any;
  @Input() message: string = 'Are you sure you want to Activate?';
  @Output() confirm = new EventEmitter<boolean>();
  @Output() convertToLeadData = new EventEmitter<any>();

  constructor(public activeModal: NgbActiveModal,
    private planVistService : PlanVistService,
    private toasterService: SkanrayToastService,
    private customerEnquiryService: CustomerEnquiryService,
    private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    console.log("convertToLeadID", this.convertToLeadID, this.cityId);
    
    if(this.isVisit) this.feedbackInit();
    if(this.convertToLeadID) {
      this.convertToLeadDropDown();
      this.initializeConvertToLeadForm();
    }
  }

  onConfirm(): void {
    this.confirm.emit(true);
    this.activeModal.close();
  }

  onConvertToLeadConfirm(): void {
    console.log("this.convertToLeadForm", this.convertToLeadForm.valid);
    console.log("this.convertToLeadForm 2", this.convertToLeadForm);
    
    this.convertToLeadData.emit(this.convertToLeadForm.value);
    this.activeModal.close();
  }

  onSubmit(): void {
    this.markFormGroupTouched(this.feedBackForm);
    if(this.feedBackForm?.valid){
      this.updateFeedbackApi();
      this.confirm.emit(true);
      this.activeModal.close();
    }
  }

  onCancel(): void {
    this.confirm.emit(false);
    this.activeModal.dismiss();
  }

  feedbackInit(){
    this.feedBackForm = this.formBuilder.group({
      message: [null, Validators.required],
      // status: [null, Validators.required],
    });
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  updateFeedbackApi(){
    const id = this.vistData?._id ?? "";
this.planVistService.feedbackUpdateForVisit(id, this.feedBackForm.value).subscribe({
  next: (response: any) => {
    if (response?.status === 200) {
      this.toasterSuccessORWarning(response);
    }
    console.log("Data sent successfully:", response);
  },
  error: (error: any) => {
    this.toasterSuccessORWarning(error);
    console.error("Error sending data:", error);
  },
  complete: () => {
    // This block will be executed when the observable completes (optional)
    console.log("Observable completed");
  },
} as Observer<any>); 
  }
  
  toasterSuccessORWarning(response:any){
    this.toasterService.showToaster(response);
  }

  initializeConvertToLeadForm() {
    this.convertToLeadForm = this.formBuilder.group({
      userList: [, Validators.required],
    });
  }

  convertToLeadDropDown(){
    this.customerEnquiryService.getConvertToLeadDropDown(this.cityId).subscribe((dropDwonData:any)=>{
      console.log("convertToLeadDropDown",dropDwonData);
      this.userListDropDown = dropDwonData?.data?.users.map((data: any) => {
        return  {
            ...data.productId,
            "label": data?.firstName + data?.lastName + `(${data?.roleId[0]?.role})`,
            "value": data._id,
          };
    })

    })
  }

  
}
