import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaNumericOnly]'
})
export class AlphaNumericOnlyDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Allow: Backspace, Tab, Enter, Escape, and arrow keys
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // Let it happen, don't do anything
      return;
    }

    if (!event.key.match(/^[a-zA-Z0-9]$/)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text/plain') || '';

    if (!this.isAlphaNumeric(pastedData)) {
      event.preventDefault();
    }
  }

  private isAlphaNumeric(value: string): boolean {
    return /^[a-zA-Z0-9]*$/.test(value);
  }
}
