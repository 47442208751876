 <div class="cart-box" (click)="cart()">
   <svg>
     <use href="assets/svg/icon-sprite.svg#Buy"></use>
   </svg>
 </div>
 <div class="cart-dropdown onhover-show-div" [ngClass]="{'active' : products == true}">
   <h6 class="f-18 mb-0 dropdown-title">Cart</h6>
   <ul>
     <li>
       <div class="d-flex"><img class="img-fluid b-r-5 img-60" src="assets/images/ecommerce/05.jpg" alt="">
         <div class="flex-grow-1"><span>Women's Track Suit</span>
           <div class="qty-box">
             <div class="input-group"><span class="input-group-prepend">
                 <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span>
               <input class="form-control input-number mx-1" type="text" name="quantity" value="1"><span
                 class="input-group-prepend">
                 <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span>
             </div>
           </div>
           <h5 class="font-primary">$500</h5>
         </div>
         <div class="close-circle"><a class="bg-danger" href="javscript:void(0)">
             <app-feathericon [icon]="'x'"></app-feathericon>
           </a></div>
       </div>
     </li>
     <li>
       <div class="d-flex"><img class="img-fluid b-r-5 img-60" src="assets/images/ecommerce/02.jpg" alt="">
         <div class="flex-grow-1"><span>Men's Jacket</span>
           <div class="qty-box">
             <div class="input-group"><span class="input-group-prepend">
                 <button class="btn quantity-left-minus" type="button" data-type="minus" data-field="">-</button></span>
               <input class="form-control input-number mx-1" type="text" name="quantity" value="1"><span
                 class="input-group-prepend">
                 <button class="btn quantity-right-plus" type="button" data-type="plus" data-field="">+</button></span>
             </div>
           </div>
           <h5 class="font-primary">$500.00</h5>
         </div>
         <div class="close-circle"><a class="bg-danger" href="javascript:void()">
             <app-feathericon [icon]="'x'"></app-feathericon>
           </a></div>
       </div>
     </li>
     <li class="total">
       <h4 class="mb-0">Order Total : <span class="f-right">$1000.00</span></h4>
     </li>
     <li class="text-center"><a class="d-block mb-3 view-cart f-w-700" [routerLink]="['/ecommerce/cart']">Go to your
         cart</a><a class="btn btn-primary view-checkout" [routerLink]="['/ecommerce/checkout']">Checkout </a></li>
   </ul>
 </div>
