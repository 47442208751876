<div class="d-flex align-items-center profile-media">
  <img class="b-r-25" src="assets/images/dashboard/profile.png" alt="">
  <div class="flex-grow-1 user"><span>{{username ? username : ''}}</span>
    <p class="mb-0 font-nunito">{{role ? role : 'Guest'}}
      <svg>
        <use href="assets/svg/icon-sprite.svg#header-arrow-down"></use>
      </svg>
      </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li>
    <a [routerLink]="'/user/users-profile'">
      <app-feathericon [icon]="'user'"></app-feathericon><span>Account </span>
    </a>
  </li>
  <li>
    <a [routerLink]="'/email/email-app'">
      <app-feathericon [icon]="'mail'"></app-feathericon><span>Inbox</span>
    </a>
  </li>
  <li>
    <a [routerLink]="'/task'">
      <app-feathericon [icon]="'file-text'"></app-feathericon>
      <span>Taskboard</span>
    </a>
  </li>
  <li>
    <a [routerLink]="'/user/users-edit'">
      <app-feathericon [icon]="'settings'"></app-feathericon>
      <span>Settings</span>
    </a>
  </li> -->
  <li><a [routerLink]="'/dashboard/profile/changePassword'">
      <app-feathericon [icon]="'settings'"></app-feathericon>
      <small>Change Password</small>
    </a></li>
  <li><a (click)="logOut()">
      <app-feathericon [icon]="'log-in'"></app-feathericon>
      <span>Log out</span>
    </a></li>
</ul>
