import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  username: string;
  role: string;

  private userLoginSubscription: Subscription;
  constructor(private router: Router, private authService: AuthenticationService) { }

  ngOnInit() {
    this.userLoginSubscription = this.authService.userLogin$.subscribe((user) => {
      this.username = user.username;
      this.role = user.role;
    });
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/auth/login'])
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.userLoginSubscription.unsubscribe();
  }
}
