import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
// import { AuthInterceptor } from "./auth.interceptor";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService
    // private toastr: ToastrService, private httpBlockerService: HttpBlockerService,
    ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (!req.url.includes(paths.error)) {
    //   return next.handle(req);
    // }
    console.warn("ErrorInterceptor");

    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        console.warn("ErrorInterceptor *** ", error);
        if (error.status === 401) {
          // 401 handled in auth.interceptor
          console.error('error from ErrorInterceptor')
          localStorage.clear();
          this.router.navigate(['auth/login']);
          this.toastr.clear();
          this.toastr.error(((error && error.error && error.error.message) ? error.error.message : 'Internal server error!'));
        }
        return throwError(error);
      })
    );
  }
}