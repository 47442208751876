import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(private httpClient: HttpClient) {}

  getAllCategoryList(page: any, limit: any, search?: any) {
    const apiUrl = `${environment.serverEndPoint}/product-category/list?page=${page}&limit=${limit}&search=${search}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getCategory(id: any) {
    const apiUrl = `${environment.serverEndPoint}/product-category/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createCategory(data: any) {
    const apiUrl = `${environment.serverEndPoint}/product-category/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateCategory(id: any, data: any) {
    const apiUrl = `${environment.serverEndPoint}/product-category/update/` + id;
    return this.httpClient.put(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  deleteCategory(id: any, data: boolean) {
    const sendData = { isDeleted: data };
    const apiUrl = `${environment.serverEndPoint}/product-category/status-update/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownCategory() {
    const apiUrl = `${environment.serverEndPoint}/product-category/dropdown/list`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
}
