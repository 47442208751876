import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private httpClient: HttpClient) {}

  getAllCompany(page: any, limit: any, search?:any) {
    const compnayList = `${environment.serverEndPoint}/company/list?page=${page}&limit=${limit}&search=${search}`;
    return this.httpClient.get(compnayList, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getCompany(id: any) {
    const compnay = `${environment.serverEndPoint}/company/` + id;
    return this.httpClient.get(compnay, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateCompany(id: any, data:any) {
    const compnay = `${environment.serverEndPoint}/company/update/` + id;
    return this.httpClient.put(compnay,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createCompany(data: any) {
    const compnay = `${environment.serverEndPoint}/company/create`;
    return this.httpClient.post<CompanyDetails>(compnay, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getCompaniesDropDownList(){
    const dropdownList = `${environment.serverEndPoint}/company/dropdown-list`;
    return this.httpClient.get<any>(dropdownList, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deleteCompany(id:any, data:boolean){
    const sendData={ isDeleted:data }
    const apiUrl = `${environment.serverEndPoint}/company/delete/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
