import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class SkanrayToastService {
    constructor(private toastr: ToastrService) { }

    showToaster(res: any) {
        if(res && res.status && (res.status >=200 && res.status < 300)){
            this.toastr.success(res?.message ? res?.message : 'Operation executed successfully!')
        } else {
            if(res && res.status && (res.status >=400)){
                this.toastr.error(res?.error?.message ? res?.error?.message : 'Internal server error!')
            }
        }
    }
    
}