<div class="d-flex justify-content-between align-items-center">
    <div class="ms-3">
      Showing {{ getDisplayedRange() }} of {{ totalItems }} items
    </div>
    <div class="d-flex align-items-center">
      <input 
      type="number" 
      class="form-control short-input me-2" 
      placeholder="Go to page" 
      [(ngModel)]="goToPageNumber"
      (keyup.enter)="goToPage(goToPageNumber)"
      min="1">
      <button class="btn btn-primary" (click)="goToPage(goToPageNumber)">Go</button>
    </div>
    <ngb-pagination
      [collectionSize]="totalItems"
      [pageSize]="pageSize"
      (pageChange)="onPageChange($event)"
      [(page)]="currentPage"
      [maxSize]="10"
      [rotate]="true"
      [ellipses]="true"
      [boundaryLinks]="true"
      aria-label="Default pagination"
    ></ngb-pagination>
  </div>
  