import { Component, ViewChild, Input, OnInit } from "@angular/core";
// import {
//   ApexAxisChartSeries,
//   ApexChart,
//   ChartComponent,
//   ApexDataLabels,
//   ApexPlotOptions,
//   ApexYAxis,
//   ApexLegend,
//   ApexStroke,
//   ApexXAxis,
//   ApexFill,
//   ApexTooltip
// } from "ng-apexcharts";

// export type ChartOptions = {
//     series: ApexAxisChartSeries;
//     chart: ApexChart;
//     dataLabels: ApexDataLabels;
//     plotOptions: ApexPlotOptions;
//     yaxis: ApexYAxis;
//     xaxis: ApexXAxis;
//     fill: ApexFill;
//     tooltip: ApexTooltip;
//     stroke: ApexStroke;
//     legend: ApexLegend;
//   };

@Component({
    selector: 'app-common-chart',
    templateUrl: 'common-chart.component.html'
})

export class CommonChartComponent implements OnInit {
    @Input() data: any;

    // @ViewChild("chart") chart: ChartComponent;
    // public chartOptions: Partial<ChartOptions>;

    constructor() { 
        // this.chartOptions = {
        //     series: [
        //       {
        //         name: "Net Profit",
        //         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        //       },
        //       {
        //         name: "Revenue",
        //         data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        //       },
        //       {
        //         name: "Free Cash Flow",
        //         data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        //       }
        //     ],
        //     chart: {
        //       type: "bar",
        //       height: 350
        //     },
        //     plotOptions: {
        //       bar: {
        //         horizontal: false,
        //         columnWidth: "55%"
        //       }
        //     },
        //     dataLabels: {
        //       enabled: false
        //     },
        //     stroke: {
        //       show: true,
        //       width: 2,
        //       colors: ["transparent"]
        //     },
        //     xaxis: {
        //       categories: [
        //         "Feb",
        //         "Mar",
        //         "Apr",
        //         "May",
        //         "Jun",
        //         "Jul",
        //         "Aug",
        //         "Sep",
        //         "Oct"
        //       ]
        //     },
        //     yaxis: {
        //       title: {
        //         text: "$ (thousands)"
        //       }
        //     },
        //     fill: {
        //       opacity: 1
        //     },
        //     tooltip: {
        //       y: {
        //         formatter: function(val) {
        //           return "$ " + val + " thousands";
        //         }
        //       }
        //     }
        //   };
    }
    
    ngOnInit() { }
}