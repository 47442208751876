import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, pipe } from "rxjs";
import { IFeatureMapping } from "../models/feature-mapping.interface";
import { RoleMappingService } from "../services/role-mapping/role-mapping.service";
import { FeaturesService } from "../services/features.service";

@Injectable({
  providedIn: 'root'
})
export class SkanrayFeaturesResolver implements Resolve<Observable<any>> {
  constructor(private roleMappingService: FeaturesService) {}

  resolve(): Observable<IFeatureMapping[]> {
    return this.roleMappingService.getFeatures();
  }
}