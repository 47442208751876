<div class="container mt-5">
  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group row mb-2">
      <label for="oldPassword" class="col-md-2 col-form-label dark-label"
        >Old Password</label
      >
      <div class="col-sm-6">
        <input
          type="password"
          placeholder="Search"
          class="form-control"
          formControlName="oldPassword"
        />
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="newPassword" class="col-md-2 col-form-label dark-label"
        >New Password</label
      >
      <div class="col-sm-6">
        <input
          type="password"
          placeholder="Search"
          class="form-control"
          formControlName="newPassword"
        />
      </div>
    </div>

    <div class="form-group row mb-2">
      <label for="confirmNewPassword" class="col-md-2 col-form-label dark-label"
        >Confirm New Password</label
      >
      <div class="col-sm-6">
        <input
          type="password"
          placeholder="Search"
          class="form-control"
          formControlName="confirmNewPassword"
        />
        <div
          *ngIf="
            changePasswordForm.controls['confirmNewPassword']?.touched &&
            changePasswordForm.controls['confirmNewPassword'].value !==
              changePasswordForm.controls['newPassword'].value
          "
          class="text text-danger mt-1"
        >
          Passwords do not match
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-offset-3 col-sm-10 buttonPadding text-center">
        <button
          type="submit"
          [disabled]="changePasswordForm.invalid"
          class="btn btn-primary mx-2"
        >
          Submit
        </button>
        <button
          type="button"
          [routerLink]="['/dashboard/admin-dashboard']"
          class="btn btn-secondary marginLeft cancelbtn"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
