 <div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version"></div>
 <div class="page-wrapper" id="pageWrapper" [ngClass]="layout.config.settings.sidebar_type">
   <app-header></app-header>
   <div class="page-body-wrapper">
     <div class="sidebar-wrapper" [ngClass]="{'close_icon' : navService.isDisplay}"
       [class.sidebar-default]="navService.isShow == true" [attr.data-layout]="layout.config.settings.icon">
       <app-sidebar></app-sidebar>
     </div>
     <div class="page-body" [attr.data-layout]="layout.config.settings.icon">
       <app-breadcrumb></app-breadcrumb>
       <router-outlet></router-outlet>
     </div>
     <app-footer></app-footer>
   </div>
 </div>
