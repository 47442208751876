<div>
  <div class="logo-wrapper" style="background-color: white;"><a [routerLink]="'/dashboard/admin-dashboard'"><img class="img-fluid"
        src="assets/images/logo/logo.png" alt=""></a>
    <div class="back-btn"><i class="fa fa-angle-left"> </i></div>
  </div>
  <nav class="sidebar-main">
    <div id="sidebar-menu">
      <ul class="sidebar-links" id="simple-bar">
        <li class="back-btn"><a [routerLink]="'/dashboard/admin-dashboard'"></a>
          <div class="mobile-back text-end"><span>Back</span><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
          </div>
        </li>
        <li class="sidebar-main-title">
          <div></div>
        </li>
        <!-- <ng-scrollbar> -->
          <li class="sidebar-list" *ngFor="let mainMenu of menus">
            <a class="sidebar-link sidebar-title" (click)=" toggleMenu(mainMenu)"
              [class.active]="mainMenu.active == true">
              <app-svg-icon *ngIf="mainMenu.icon" [icon]="mainMenu.icon"></app-svg-icon>
              <span>{{mainMenu.mainTitle! | translate }}</span>
            </a>
            <ng-container *ngIf="mainMenu.item">
              <ul class="sidebar-submenu theme-scrollbar" [ngStyle]="{ 'display' : mainMenu.active ? 'block' : 'none' }">
                <li class="sidebar-head">{{ mainMenu.headTitle1! | translate }}</li>
                <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: mainMenu.item }"></div>
                <ng-template #recursiveMenu let-mainMenu.item>
                  <ng-container *ngFor="let item of mainMenu.item">
                    <li *ngIf="item.children; else simple" class="main-submenu">
                      <a class="d-flex sidebar-menu" href="javascrip:void(0)" (click)="toggle(item)"
                        [routerLink]="item.path" [ngClass]="item.active ? 'active' : ''">
                        <app-svg-icon *ngIf="item.icon" [icon]="item.icon">
                        </app-svg-icon>
                        {{ item.title! | translate }}
                        <svg class="arrow" *ngIf="item.children">
                          <use href="assets/svg/icon-sprite.svg#Arrow-right"></use>
                        </svg>
                      </a>
                      <ul *ngIf="item.children" class="submenu-wrapper" 
                        [ngStyle]="{ 'display' : item.active ? 'block' : 'none'}">
                        <li *ngFor="let child of item.children">
                          <a *ngIf="!child.children; else children" (click)="toggle(child,mainMenu)"
                            [routerLink]="child.path">{{ child.title! | translate }} </a>
                          <ng-template #children>
                            <a class="submenu-title" href="javascrip:void(0)" (click)="toggle(child)"
                              [ngClass]="child.active ? 'active' : '' " [routerLink]="child.path">
                              {{ child.title!| translate }}
                              <div class="according-menu">
                                <i class="fa fa-angle-{{ child.active ? 'down' : 'right' }} pull-right" *ngIf="child.children"></i>
                              </div>
                            </a>
                          </ng-template>
                          <ul *ngIf="child.children" class="nav-sub-childmenu submenu-content"
                            [ngStyle]="{ 'display' : child.active ? 'block' : 'none' }">
                            <li *ngFor="let sub of child.children">
                              <a *ngIf="sub.type == 'extTabLink'; else submenu" href="{{ sub.path }}" target="_blank"> {{ sub.title! | translate }} </a>
                              <ng-template #submenu>
                                <a [routerLink]="sub.path" href="javascrip:void(0)" (click)="toggle(sub,mainMenu)">{{ sub.title!| translate }}</a>
                              </ng-template>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <ng-template #simple>
                      <li class="main-submenu">
                        <a class="d-flex" [routerLink]="item.path" (click)="toggle(item,mainMenu)">
                          <app-svg-icon *ngIf="item.icon" [icon]="item.icon"></app-svg-icon>
                            {{ item.title! | translate }}
                        </a>
                      </li>
                    </ng-template>
                  </ng-container>
                </ng-template>
              </ul>
            </ng-container>
            
          </li>
        <!-- </ng-scrollbar> -->
        
      </ul>
    </div>
  </nav>
</div>