import { Routes } from '@angular/router';

export const dashData: Routes = [
    {
        path: 'dashboard',
        data: {
            title: "Dashboard",
            breadcrumb: "Dashboard",

        },
        loadChildren: () => import('../../component/dashboards/dashboards.module').then(m => m.DashboardsModule)
    },
    {
        path: 'approvals',
        data: {
            title: "approvals",
            breadcrumb: "approvals",

        },
        loadChildren: () => import('../../component/approvals/approvals.module').then(m => m.ApprovalsModule)
    },
    {
        path: 'manage-company',
        data: {
            title: "Company",
            breadcrumb: "Company",

        },
        loadChildren: () => import('../../component/company/company.module').then(m => m.CompanyModule)
    },    
    {
        path: 'admin',
        data: {
            title: "Admin",
            breadcrumb: "Admin",

        },
        loadChildren: () => import('../../component/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'manage-channel-partner',
        data: {
            title: "channel-partner",
            breadcrumb: "Manage Channel Partner",

        },
        loadChildren: () => import('../../component/manage-channel-partner/manage-channel-partner.module').then(m => m.ManageChannelPartnerModule)
    },
    {
        path: 'manage-territory',
        data: {
            title: "manage-territory",
            breadcrumb: "manage-territory",

        },
        loadChildren: () => import('../../component/manage-territory/manage-territory.module').then(m => m.ManageTerritoryModule)
    },
    {
        path: 'manage-branch',
        data: {
            title: "manage-branch",
            breadcrumb: "manage-branch",

        },
        loadChildren: () => import('../../component/branch/manage-branch.module').then(m => m.ManageBranchModule)
    },
    {
        path: 'manage-customer',
        data: {
            title: "manage-customer",
            breadcrumb: "manage-customer",

        },
        loadChildren: () => import('../../component/manage-customer/manage-customer.module').then(m => m.ManageCustomerModule)
    },
    {
        path: 'manage-product',
        data: {
            title: "manage-product",
            breadcrumb: "manage-product",

        },
        loadChildren: () => import('../../component/manage-product/manage-product.module').then(m => m.ManageProductModule)
    },
    {
        path: 'manage-user',
        data: {
            title: "manage-user",
            breadcrumb: "manage-user",

        },
        loadChildren: () => import('../../component/manage-user/manage-user.module').then(m => m.ManageUserModule)
    },
    {
        path: 'manage-currency',
        data: {
            title: "manage-currency",
            breadcrumb: "manage-currency",

        },
        loadChildren: () => import('../../component/manage-currency/manage-currency.module').then(m => m.ManageCurrencyModule)
    },
    {
        path: 'manage-web-enquiries',
        data: {
            title: "web-enquiries",
            breadcrumb: "Manage Web Enquiries",

        },
        loadChildren: () => import('../../component/manage-web-enquiries/manage-web-enquiries.module').then(m => m.ManageWebEnquiriesModule)
    },
    {
        path: 'manage-lead',
        data: {
            title: "manage-lead",
            breadcrumb: "manage-lead",

        },
        loadChildren: () => import('../../component/leads/leads.module').then(m => m.ManageLeadsModule)
    },
    {
        path: 'vist-demo',
        data: {
            title: "vist-demo",
            breadcrumb: "vist-demo",

        },
        loadChildren: () => import('../../component/plan-vist-demo/plan-vist-demo.module').then(m => m.PlanVistDemoModule)
    },
    {
        path: 'roles-access',
        data: {
            title: "roles-access",
            breadcrumb: "roles-access",

        },
        loadChildren: () => import('../../component/manage-roles-and-access/manage-roles-and-access.module').then(m => m.ManageRolesAndAccessModule)
    },
    {
        path: 'opportunity',
        data: {
            title: "opportunity",
            breadcrumb: "opportunity",

        },
        loadChildren: () => import('../../component/opportunity/opportunity/opportunity.module').then(m => m.OpportunityModule)
    },
    {
        path: 'vertical',
        data: {
            title: "vertical",
            breadcrumb: "vertical",

        },
        loadChildren: () => import('../../component/vertical/vertical.module').then(m => m.VerticalModule)
    },
    {
        path: 'manage-document',
        data: {
            title: "manage-document",
            breadcrumb: "manage-document",

        },
        loadChildren: () => import('../../component/manage-document/manage-routing.module').then(m => m.ManageRoutingModule)
    },
    {
        path: 'logreport',
        data: {
            title: "logreport",
            breadcrumb: "logreport",

        },
        loadChildren: () => import('../../component/log-report/logreport.module').then(m => m.LogreportModule)
    },
    {
        path: 'customer-approval',
        data: {
            title: "customerApproval",
            breadcrumb: "customerApproval",

        },
        loadChildren: () => import('../../component/customer-approval/customer-approval.module').then(m => m.CustomerApprovalModule)
    },
    {
        path: 'calendar',
        data: {
            title: "Calendar",
            breadcrumb: "Calendar",

        },
        loadChildren: () => import('../../component/event-demo-calendar/event-demo-calendar.module').then(m => m.EventDemoCalendarModule)
    },
    {
        path: 'live-locations',
        data: {
            title: "Map",
            breadcrumb: "Map",

        },
        loadChildren: () => import('../../component/live-location-map/live-location-map.module').then(m => m.MapsModule)
    },
    {
        path: 'so-number-entry',
        data: {
            title: "Map",
            breadcrumb: "Map",

        },
        loadChildren: () => import('../../component/so-number/so-number.module').then(m => m.SoNumberModule)
    },
    {
        path: 'settings',
        data: {
            title: "settings",
            breadcrumb: "settings",

        },
        loadChildren: () => import('../../component/settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'delete_contract_note',
        data: {
            title: "delete cNote",
            breadcrumb: "delete cNote",

        },
        loadChildren: () => import('../../component/delete-contract-notes/delete-contract-notes.module').then(m => m.DeleteContractNotesModule)
    },
    {
        path: 'reports',
        data: {
            title: "reports",
            breadcrumb: "reports",

        },
        loadChildren: () => import('../../component/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'graphs',
        data: {
            title: 'Graphs',
            breadcrumb: 'All'
        },
        loadChildren: () => import('../../component/graphs/graph.module').then(m => m.GraphModule)
    },
    {
        path: 'distributor',
        data: {
            title: 'Distributor',
            breadcrumb: 'All'
        },
        loadChildren: () => import('../../component/distributor/distributor.module').then(m => m.DistributorModule)
    },
    {
        path: 'inActiveOpportunity',
        data: {
            title: 'inActive-Opportunity',
            breadcrumb: 'All'
        },
        loadChildren: () => import('../../component/inactive-opprtunity/inactive-opprtunity.module').then(m => m.InactiveOpprtunityModule)
    }
    
]