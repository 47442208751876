import { IFeatureMapping } from "src/app/shared/models/feature-mapping.interface";

export interface IfeatureDTO {
    moduleNumber: string;
    moduleName: string;
    active: boolean;
}
export const DummyEncToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbGFtQHhvb2xpdC5jb20iLCJyb2xlcyI6WyJEQVRBX09QRVJBVE9SIl0sIl9pZCI6IjYyZDI4Yzc4NjE4NTNmZWVhZDBkZmFkYiIsImFjY2Vzc0NvZGVzIjpbInhvb2wxODU0MDExMzUxMTAwMSIsInhvb2wxODU0MDExMzUxMTAwMiIsInhvb2wxODU0MDExMzUxMTAwMyIsInhvb2w2ODIxMzUzMDU4MTAwMSIsInhvb2w2ODIxMzUzMDU4MTAwMCIsInhvb2w2ODIxMzUzMDU4MTAwMiIsInhvb2w2ODIxMzUzMDU4MTAwMyIsInhvb2wyOTkzODYwNTA2MTAwMSIsInhvb2w2MzI1NDIwMTc1MTAwMSIsInhvb2w2MzI1NDIwMTc1MTAwMCIsInhvb2w2MzI1NDIwMTc1MTAwMiIsInhvb2w2MzI1NDIwMTc1MTAwMyIsInhvb2w5MDQzNzEwMTg3MTAwMSIsInhvb2w5MDQzNzEwMTg3MTAwMCIsInhvb2w5MDQzNzEwMTg3MTAwMiIsInhvb2w5MDQzNzEwMTg3MTAwMyIsInhvb2wyOTkzODYwNTA2MTAwMiJdLCJpYXQiOjE3MDE0OTgwNjksImV4cCI6MTczNzQ5ODA2OX0.288I9aGLWYuHGGcSha5GMWD-NX7OIPYqnSrY1OjdWrs`;

export const DummyDecToken = {
    "email": "malam@xoolit.com",
    "_id": "62d28c7861853feead0dfadb",
    "accessCodes": [
        "xool18540113511001",
        "xool18540113511002",
        "xool18540113511003",
        "xool68213530581001",
        "xool68213530581000",
        "xool68213530581002",
        "xool68213530581003",
        "xool29938605061001",
        "xool63254201751001",
        "xool63254201751000",
        "xool63254201751002",
        "xool63254201751003",
        "xool90437101871001",
        "xool90437101871000",
        "xool90437101871002",
        "xool90437101871003",
        "xool29938605061002"
    ],
    "iat": 1701492982,
    "exp": 1737492982
}

export const FeatureDataJSON: IFeatureMapping[] = [
    {
        _id: null,
        moduleName: 'ManageCompany',
        moduleNumber: 'xool2144306858',
        active: true
    },
    {   _id: null,
        moduleName: 'ManageAdminUser',
        moduleNumber: 'xool9317077413',
        active: true
    },
    {    _id: null,
        moduleName: 'ManageEmail',
        moduleNumber: 'xool1854011351',
        active: true
    },
    {    _id: null,
        moduleName: 'ManageLeads',
        moduleNumber: 'xool408431274',
        active: true
    }
]