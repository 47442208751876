import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAuthenticationResponseDTO, ILoginUserDTO } from '../models/user.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService{
    private loggedInRole = new Subject<string>();
    private userLoginSubject = new BehaviorSubject<{ username: string; role: string }>({ username: '', role: '' });
    checkRoleObservable$ = this.loggedInRole.asObservable();
    userLogin$ = this.userLoginSubject.asObservable();

    constructor(private http:HttpClient) {}
    
    loginByEmployeeID(userDetails: ILoginUserDTO): Observable<IAuthenticationResponseDTO> {
        return this.http.post<IAuthenticationResponseDTO>(`${environment.serverEndPoint}/users/login`, userDetails);
    }

    setRoleObservable(role: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if(role) {
                this.loggedInRole.next(role);
                resolve(true);
            } else {
                reject(false);
            }
        })
    }

    setUserDetails(username: string, role: string) {
        this.userLoginSubject.next({ username, role });
    }

    
  
    setLoginDetailsData(newData: any): void {
        console.log("......", newData);  
        const setLoginDetails ={
            employeeId : newData?.employeeId ?? "",
            id : newData?.id || "",
            name : newData?.name || "",
            region : newData?.region || "",
            userType : newData?.userType || "",
        }     
        console.log("...setLoginDetails...", setLoginDetails);  
       localStorage.setItem( btoa('loginDetails'),  btoa(JSON.stringify(setLoginDetails)));
    }

}