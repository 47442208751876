import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class RegionService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}

  getDropdownRegionList(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/region/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  getDropdownRegionListWithoutAuth(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/region/no-auth/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAllRegionList(params: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/region/list`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  exportAllRegionList(params: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/region/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getRegion(id: any) {
    const apiUrl = `${environment.serverEndPoint}/region/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createRegion(data: any) {
    const apiUrl = `${environment.serverEndPoint}/region/create`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  
  updateRegion(id: any, data:any) {
    const apiUrl = `${environment.serverEndPoint}/region/` + id;
    return this.httpClient.put(apiUrl,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

}
