<div class="card o-hidden">
    <div class="chart-widget-top">
      <div class="row card-body pb-0 m-0">
        <div class="col-xl-9 col-lg-8 col-9 p-0">
          <h3 class="mb-2">{{data?.title?.text}}</h3>
        </div>
      </div>
      <div *ngIf="data?.series">
        <div id="chart-widget1">
          <apx-chart [series]="data.series" [chart]="data.chart" [dataLabels]="data.dataLabels" [grid]="data.grid"
            [stroke]="data.stroke" [xaxis]="data.xaxis" [yaxis]="data.yaxis" [responsive]="data.responsive"
            [tooltip]="data.tooltip" [colors]="data.colors">
          </apx-chart>
        </div>
      </div>

      <div *ngIf="data?.pieseries">
        <div id="chart-widget2">
          <!-- <apx-chart [series]="data.pieseries" [chart]="data.chart" [labels]="data.labels" 
            [responsive]="data.responsive"  [legend]="data.legend" 
            [tooltip]="data.tooltip" [colors]="data.colors">
          </apx-chart> -->
          <apx-chart
          [series]="data.pieseries"         
          [chart]="data.chart"
          [labels]="data.labels"          
          [legend]="data.legend" 
          [dataLabels]="data.dataLabels"         
          [responsive]="data.responsive"
          [tooltip]="data.tooltip"
          [colors]="data.colors"
        ></apx-chart>
        </div>
      </div>
    </div>


    <!-- <div id="chart">
        <apx-chart
          [series]="chartOptions?.series!"
          [chart]="chartOptions?.chart!"
          [dataLabels]="chartOptions?.dataLabels!"
          [plotOptions]="chartOptions?.plotOptions!"
          [xaxis]="chartOptions?.xaxis!"
          [yaxis]="chartOptions?.yaxis!"
          [legend]="chartOptions?.legend!"
          [fill]="chartOptions?.fill!"
          [tooltip]="chartOptions?.tooltip!"
          [stroke]="chartOptions?.stroke!"
        ></apx-chart>
      </div> -->
</div>
  