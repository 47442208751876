import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class CityService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}

  getDropdownCityList(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/city/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownCityListWithoutAuth(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/city/no-auth/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getMultiDropdownCityList(data?: any) {
    
    const apiUrl = `${environment.serverEndPoint}/districts/cities-list`;
   
    return this.httpClient.post(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAllCityList(params: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/city/list`
    // if (fiter.companyId) apiUrl = `${apiUrl}&companyId=${fiter.companyId}`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
    
  }

  getCity(id: any) {
    const apiUrl = `${environment.serverEndPoint}/city/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createCity(data: any) {
    const apiUrl = `${environment.serverEndPoint}/city/create`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateCity(id: any, data:any) {
    const apiUrl = `${environment.serverEndPoint}/city/` + id;
    return this.httpClient.put(apiUrl,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deleteCity(id: any, data: boolean){
    const sendData = { isDeleted: data };
    const apiUrl = `${environment.serverEndPoint}/city/` + id;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        isDeleted: data
      },
    };
    return this.httpClient.delete(apiUrl, options);
  }

  exportList(params: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/city/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
