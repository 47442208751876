<div *ngIf="isLoading" class="loader">
  <i class="fas fa-spinner fa-spin"></i>
</div>
<div *ngIf="!isLoading">

<form [formGroup]="dynamicFormGroup">
  <ng-container *ngFor="let control of form?.formControls">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label control-label"
        >{{ control?.label }}
        <span class="required" *ngIf="control.required">*</span></label
      >
      <div class="col-sm-6">
        <ng-container
          *ngIf="
            ['text', 'email', 'password', 'number', 'date'].includes(
              control.type
            )
          "
        >
          <input
            formControlName="{{ control?.name }}"
            [type]="control?.type"
            class="form-control"
            (blur)="onBlurEvent(control?.name, $event)"
          />
        </ng-container>

        <ng-container *ngIf="['textarea'].includes(control.type)">
          <textarea
            formControlName="{{ control?.name }}"
            class="form-control"
          ></textarea>
        </ng-container>

        <ng-container *ngIf="control?.type === 'datalist'">
          <!-- <ng-select *ngIf="control.name != 'branch' && control.name != 'currency' -->
          <!-- &&  control.name != 'geoName'" -->
          <ng-select
            *ngIf="
              ![
                'branch',
                'currency',
                'geoName',
                'countryName',
                'regionName',
                'customerId',
                'stateName',
                'districtName',
                'salutation',
                'speciality',
                'customer',
                'productType',
                'segment',
                'category',
                'subSystem'
              ].includes(control.name)
            "
            [items]="companyDropDownList"
            [bindLabel]="
              control.name === 'productCategory'
                ? 'categoryTitle'
                : 'companyName'
            "
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'branch'"
            [items]="getDropDownList"
            bindLabel="branchName"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="control.name === 'currency'"
            [items]="currencyDropDownList"
            bindLabel="name"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'geoName'"
            [items]="getDropDownList"
            bindLabel="geoName"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
            (change)="geoChangeHandler()"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'regionName'"
            [items]="getDropDownList"
            bindLabel="name"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
            (change)="callHandler('region')"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'countryName'"
            [items]="getDropDownList"
            bindLabel="name"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
            (change)="countryChangeHandler()"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'stateName'"
            [items]="getDropDownList"
            bindLabel="name"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
            (change)="callHandler('district')"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'districtName'"
            [items]="getDropDownList"
            bindLabel="name"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select District"
            (change)="callHandler('city')"
          ></ng-select>
          <ng-select
            *ngIf="control.name === 'customerId'"
            [items]="customerTypeDropDownList"
            bindLabel="name"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="control.name === 'customer'"
            [items]="customerDropDownList"
            bindLabel="customerName"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'speciality'"
            [items]="specialityTypeDropDownList"
            bindLabel="specialityName"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="control.name == 'salutation'"
            [items]="control.options ?? []"
            bindLabel="value"
            bindValue="id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="
              control.name == 'productType' && control?.component === 'product'
            "
            [items]="control.options ?? []"
            bindLabel="value"
            bindValue="id"
            formControlName="{{ control?.name }}"
            placeholder="Select Product Type"
          ></ng-select>
          <ng-select
            *ngIf="
              control.name == 'category' && control?.component === 'product'
            "
            [items]="categoryDropDownList"
            bindLabel="categoryTitle"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
            (change)="productCategoryChange()"
          ></ng-select>
          <ng-select
            *ngIf="
              control.name == 'segment' && control?.component === 'product'
            "
            [items]="segmentDropDownList"
            bindLabel="segmentName"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
          <ng-select
            *ngIf="
              control.name == 'subSystem' && control?.component === 'product'
            "
            [items]="subSystemDropDownList"
            bindLabel="subSystemName"
            bindValue="_id"
            formControlName="{{ control?.name }}"
            placeholder="Select"
          ></ng-select>
        </ng-container>

        <ng-container *ngIf="control?.type === 'select'">
        <ng-container *ngIf="control?.name === 'currency' else nonCurrency">
      
          <select
            formControlName="{{ control?.name }}"
            class="form-select"
            aria-label="Default select example"
          >
            <option selected value="">Choose....</option>
            <ng-container *ngFor="let opt of currencyDropDownList">
              <option [value]="opt._id">{{ opt.name }}</option>
            </ng-container>
          </select>
        </ng-container>
          <ng-template #nonCurrency >
            <select 
            formControlName="{{ control?.name }}"
            class="form-select"
            aria-label="Default select example"
          >
            <option selected value="">Choose....</option>
            <ng-container *ngFor="let opt of control?.options">
              <option [value]="opt.value">{{ opt.value }}</option>
            </ng-container>
          </select>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="control?.type === 'radio'">
          <div class="d-flex">
            <div
              class="form-check custom-radio"
              *ngFor="let opt of control?.radioOptions"
            >
              <input
                class="form-check-input"
                type="radio"
                [value]="opt"
                [id]="control?.name + opt"
                formControlName="{{ control?.name }}"
              />
              <label class="form-check-label" [for]="control?.name + opt">{{
                opt
              }}</label>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="control?.type === 'checkbox'">
          <form [formGroup]="checkBoxForm" class="form-group row">
            <div
              class="col-md-3"
              *ngFor="let option of radioList; let i = index"
            >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [value]="option.competitorTitle"
                  [checked]="isCompetitorSelected(option.competitorTitle)"
                  (change)="onCheckboxChange($event)"
                />
                <label
                  class="form-check-label"
                  [for]="'checkbox' + option._id"
                  >{{ option.competitorTitle }}</label
                >
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container
          *ngIf="
            ['mobile'].includes(control.type) &&
            !['fax', 'telephone'].includes(control.name)
          "
        >
          <form [formGroup]="myForm" class="form-group row">
            <div class="col-sm-2">
              <ng-select
                [items]="countryCodeDropDownList"
                formControlName="countryCode"
                placeholder="Select"
              ></ng-select>
            </div>
            <div class="col-sm-10">
              <input
                type="text"
                formControlName="mobileNumber"
                id="mobileNumber"
                class="form-control padBtm"
                maxlength="15"
                placeholder="Enter mobile number"
              />
            </div>
          </form>
        </ng-container>

        <ng-container
          *ngIf="
            ['mobile'].includes(control.type) && control.name === 'telephone'
          "
        >
          <form [formGroup]="telePhoneForm" class="form-group row">
            <div class="col-sm-2">
              <ng-select
                [items]="countryCodeDropDownList"
                formControlName="telePhoneCode"
                placeholder="Select"
              ></ng-select>
            </div>
            <div class="col-sm-10">
              <input
                type="text"
                formControlName="telePhoneNumber"
                id="telePhoneNumber"
                class="form-control padBtm"
                maxlength="15"
                placeholder="Enter mobile number"
              />
            </div>
          </form>
        </ng-container>

        <ng-container
          *ngIf="['mobile'].includes(control.type) && control.name === 'fax'"
        >
          <form [formGroup]="faxForm" class="form-group row">
            <div class="col-sm-2">
              <ng-select
                [items]="countryCodeDropDownList"
                formControlName="faxCountryCode"
                placeholder="Select"
              ></ng-select>
            </div>
            <div class="col-sm-10">
              <input
                type="text"
                formControlName="faxNumber"
                id="faxNumber"
                class="form-control padBtm"
                maxlength="15"
                placeholder="Enter mobile number"
              />
            </div>
          </form>
        </ng-container>

        <small
          *ngIf="
            dynamicFormGroup.get(control.name)?.touched &&
            dynamicFormGroup.get(control.name)?.invalid
          "
          class="text-danger"
        >
          {{ getErrorMessage(control) }}
        </small>
      </div>
    </div>
  </ng-container>

  <div class="col-12 text-center">
    <button (click)="onSubmit()" type="button" class="btn btn-primary mx-2">
      {{ form.saveBtnTitle }}
    </button>
    <button
      [routerLink]="[form.route]"
      type="button"
      class="btn btn-secondary mx-2"
    >
      {{ form.resetBtnTitle }}
    </button>
  </div>
</form>
</div>
