import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
@Injectable({
  providedIn: "root",
})
export class CompetitorService {
  constructor(private httpClient: HttpClient) {}

  getAllCompetitorList(page: any, limit: any, search?: any) {
    const apiUrl = `${environment.serverEndPoint}/competitor/list?page=${page}&limit=${limit}&search=${search}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getCompetitor(id: any) {
    const apiUrl = `${environment.serverEndPoint}/competitor/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createCompetitor(data: any) {
    const apiUrl = `${environment.serverEndPoint}/competitor/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateCompetitor(id: any, data: any) {
    const apiUrl = `${environment.serverEndPoint}/competitor/update/` + id;
    return this.httpClient.put(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deleteCompetitor(id: any, data: boolean) {
    const sendData = { isDeleted: data };
    const apiUrl = `${environment.serverEndPoint}/competitor/status-update/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownCompetitor() {
    const apiUrl = `${environment.serverEndPoint}/competitor/dropdown/list`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
}
