 <div class="message notification-box" (click)="Message()">
   <svg>
     <use href="assets/svg/icon-sprite.svg#Message"></use>
   </svg>
   <span class="rounded-pill badge-secondary"> </span>
 </div>
 <div class="onhover-show-div message-dropdown" [ngClass]="{'active' : MassageData == true}">
   <h6 class="f-18 mb-0 dropdown-title">Message </h6>
   <ul>
     <li>
       <div class="d-flex align-items-start">
         <div class="message-img bg-light-primary"><img src="assets/images/user/3.jpg" alt=""></div>
         <div class="flex-grow-1">
           <h5 class="mb-1"><a [routerLink]="'/email/email-app'">Vinay Kumar</a></h5>
           <p>Do you want to go see movie?</p>
         </div>
         <div class="notification-right">
           <app-feathericon [icon]="'x'"></app-feathericon>
         </div>
       </div>
     </li>
     <li>
       <div class="d-flex align-items-start">
         <div class="message-img bg-light-primary"><img src="assets/images/user/6.jpg" alt=""></div>
         <div class="flex-grow-1">
           <h5 class="mb-1"><a [routerLink]="'/email/email-app'">Shiva</a></h5>
           <p>Thank you for rating us.</p>
         </div>
         <div class="notification-right">
           <app-feathericon [icon]="'x'"></app-feathericon>
         </div>
       </div>
     </li>
     <li>
       <div class="d-flex align-items-start">
         <div class="message-img bg-light-primary"><img src="assets/images/user/10.jpg" alt=""></div>
         <div class="flex-grow-1">
           <h5 class="mb-1"><a [routerLink]="'/email/email-app'">Ramaswamy</a></h5>
           <p>What`s the project report update?</p>
         </div>
         <div class="notification-right">
           <app-feathericon [icon]="'x'"></app-feathericon>
         </div>
       </div>
     </li>
     <li><a class="f-w-700" [routerLink]="'/email/email-app'">Check all</a></li>
   </ul>
 </div>
