import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { RoleMappingHelperService } from '../services/role-mapping/role-mapping-helper.service';

@Directive({
  selector: '[accessControl]'
})
export class AccessControlDirective {
  @Input("moduleName") moduleName: string;
  @Input("accessType") accessType: string;
  @Input('canDisabled') canDisabled: boolean;
  @Input('displayType') displayType: string;

  constructor(private elementRef: ElementRef,
              private roleMappingHelper: RoleMappingHelperService,
              private renderer: Renderer2) {}

  ngOnInit() {
    this.elementRef.nativeElement.style.display = "none";
    this.checkAccess();
  }

  checkAccess() {
    const module: any = this.roleMappingHelper.getModuleByName(this.moduleName);
    const moduleCode = this.roleMappingHelper.getAccessCodeByType(this.accessType, module);
    // console.log('moduleCode', moduleCode);
    if (!this.hasPermission(moduleCode)) {
        this.updateAccess();
    } else {
      this.elementRef.nativeElement.style.display = this.displayType ? this.displayType : "block";
    }
  }
  
  private updateAccess() {
    if (this.canDisabled) {
      this.elementRef.nativeElement.style.display = this.displayType ? this.displayType : 'block';
       this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true')
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
  
  private hasPermission(moduleCode: string): boolean {
    const accessControls: string[] = this.roleMappingHelper.ACCESS_CODES;
    return this.roleMappingHelper.isAdmin() || accessControls.includes(moduleCode)
  }

}
