
const MODULE_ACCESS_CODE = {
    all: '0000',
    read: '0001',
    write: '0002',
    delete: '0003'
}

export const ROLE_MAPPING = {
    MODULE_ACCESS_CODE,
}

export const ROLES = {
    // ADMIN_MARKETING: 'Admin Marketing',
    // SALES_ENGINEER: 'Sales Engineer',
    DISTRIBUTOR: 'Distributor',
    // REGIONAL_SALES_MANAGER: 'Regional Sales Manager',
    // REGIONAL_BRANCH_HEAD: 'Regional Branch Head',
    // NATIONAL_SALES_MANAGER: 'National Sales Manager',
    // COUNTRY_HEAD: 'Country Head',
    // SALES_DIRECTOR: 'Sales Director',
    // GLOBAL_HEAD: 'Global Head',
    STOCKIST: 'Stockist',
    // CUSTOMER_INTERACTION_CENTER: 'Customer Interaction Center',
    // OTR: 'Other'
}