import { Injectable } from "@angular/core";
import { IFeatureMapping } from "../../models/feature-mapping.interface";
import { ROLE_MAPPING } from "../../enum/role-mapping.constant";

@Injectable({
    providedIn: 'root'
})
export class RoleMappingHelperService {
    private moduleAccessCodes: string[] = [];
    private features: IFeatureMapping[];
    private _isAdmin = false;

    constructor() {

    }

    set ACCESS_CODES(codes: string[]) {
        this.moduleAccessCodes = codes;
    }

    get ACCESS_CODES() {
        return this.moduleAccessCodes;
    }

    set FEATURES(features: IFeatureMapping[]) {
        this.features = features;
    }

    get FEATURES() {
        return this.features;
    }

    set IS_ADMIN(role: string) {
        this._isAdmin = role === "SUPER_ADMIN" || role === "ADMIN";
    }

    isAdmin(): boolean {
        return this._isAdmin;
    }

    getModuleByName(name: string|undefined): IFeatureMapping | null | undefined{
        // console.log('getModuleByName', this.features);
        // console.log('name', name);
        if (!this.features?.length) {
            return null;
        }
        return this.features.find((feature: any) => feature.moduleName === name);
    }

    getAccessCodeByType(type: string, feature: IFeatureMapping) {
        switch (type) {
            case 'read':
                return this.readAccess(feature);
            
            case 'write':
                return this.writeAccess(feature);

            case 'delete': 
                return this.deleteAccess(feature);
            
            case 'all':
                return this.fullAccess(feature); 
            default:
                return this.readAccess(feature);
        }
    }

    getModuleAccessCode(feature: IFeatureMapping, code: string): string {
        return `${feature.moduleNumber}${code}`
    }

    readAccess(feature: IFeatureMapping): string {
        return this.getModuleAccessCode(feature, ROLE_MAPPING.MODULE_ACCESS_CODE.read);
    }

    writeAccess(feature: IFeatureMapping): string {
        return this.getModuleAccessCode(feature, ROLE_MAPPING.MODULE_ACCESS_CODE.write);
    }

    deleteAccess(feature: IFeatureMapping): string {
        return this.getModuleAccessCode(feature, ROLE_MAPPING.MODULE_ACCESS_CODE.delete);
    }

    fullAccess(feature: IFeatureMapping): string {
        return this.getModuleAccessCode(feature, ROLE_MAPPING.MODULE_ACCESS_CODE.all);
    }

}
