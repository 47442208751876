import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SkanrayToastService } from "../shared/services/common/skanray-toast.service";
import { EncryptDecryptService } from "../shared/services/encryption.service";
import { CustomerEnquiryService } from "../shared/services/manage-web-enquiry/customerEnquiry.service";
import * as countriesList from "countries-list";
import { IGeoDetails } from "../shared/data/component/models-interfaces/manage-territory/geo-interface";
import { ICountryDropdownOption } from "../shared/data/component/models-interfaces/manage-territory/country-interface";
import { CountryService } from "../shared/services/manage-territory/country.service";
import { GeoService } from "../shared/services/manage-territory/geo.service";
import { RegionService } from "../shared/services/manage-territory/region.service";
import { StateService } from "../shared/services/manage-territory/state.service";
import { DistrictService } from "../shared/services/manage-territory/district.service";
import { CityService } from "../shared/services/manage-territory/city.service";
import { ProductService } from "../shared/services/manage-product/product.service";
const countryCodes: string[] = Object.keys(countriesList.countries)?.map(
  (code) => (countriesList.countries as any)[code].phone
);
export default countryCodes;

@Component({
  selector: "app-webenquiryform",
  templateUrl: "./webenquiryform.component.html",
  styleUrls: ["./webenquiryform.component.scss"],
})
export class WebenquiryformComponent {
  webEnquiryForm: FormGroup;
  decryptedData: any;
  dataLoaded = false;
  countryCodeDropDownList: any;
  formSubTittle = 'If you have any queries,kindly take a moment to fill up this form,and our representatives will contact you shortly.'
  geoList: IGeoDetails[] = [];
  countryList: ICountryDropdownOption[];
  regionList: any[] = [];
  stateList: any[] = [];
  districtList: any[] = [];
  cityList: any[] = [];
  productList: any[] = [];
  sourceOfLeads: any[] = [
    {
      value: 'VISIT',
      display: 'Visit'
    },
    {
      value: 'MARKETING_CAMPAIGN',
      display: 'Marketing Campaign'
    },
    {
      value: 'REFERRAL',
      display: 'Referral'
    },
    {
      value: 'TELEPHONIC',
      display: 'Telephonic'
    },
    {
      value: 'CUSTOMER_INTERACTION_CENTER',
      display: 'Customer Interaction Center'
    },
    {
      value: 'WEB_INQUIRY_CHAT',
      display: 'Web Inquiry Chat'
    },
    {
      value: 'WEB_INQUIRY_REQUEST_CALLBACK',
      display: 'Web Inquiry Request Callback'
    },
    {
      value: 'WEB_INQUIRY_PRODUCT_DEMO',
      display: 'Web Inquiry Product Demo'
    },
    {
      value: 'WEB_INQUIRY_TALK_TO_PRODUCT_EXPERT',
      display: 'Web Inquiry - Talk To Product Expert'
    }
  ];
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private countryService: CountryService,
    private geoService: GeoService,
    private districtService: DistrictService,
    private stateService: StateService,
    private cityService: CityService,
    private regionService: RegionService,
    private productService: ProductService,
    private customerEnquiryService: CustomerEnquiryService,
    private encryptDecryptService: EncryptDecryptService,
    private toasterService: SkanrayToastService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['id']) {
        const encodedId = params['id']
        this.decryptedData = encodedId
          ? this.encryptDecryptService.decryptObject(encodedId)
          : null;
      }
    });
  }

  ngOnInit() {
    const flattenedArray: (number | string)[] = (
      [] as (number | string)[]
    ).concat(...countryCodes);
    this.countryCodeDropDownList = [...flattenedArray];
    this.initializeForm();
    this.getProductDropDown();
    this.getGeoList();
    if (this.decryptedData) {
      const date1 = new Date(this.decryptedData.expiryDate);
      const date2 = new Date();
      date2.setHours(0, 0, 0, 0);
      if (date2 <= date1) {
        this.dataLoaded = true;
      } else this.dataLoaded = false;
    }
  }

  initializeForm() {
    this.webEnquiryForm = this.fb.group({
      companyId: [this.decryptedData.companyId, Validators.required],
      name: [, Validators.required],
      email: [, Validators.required],
      countryCode: [91, Validators.required],
      mobileNumber: [, Validators.required],
      sourceOfLead: [, Validators.required],
      message: [, Validators.required],
      product: [, Validators.required],
      geo: [, Validators.required],
      country: [, Validators.required],
      state: [, Validators.required],
      region: [, Validators.required],
      district: [, Validators.required],
      city: [{ value: null, disabled: true }, Validators.required],
    });
  }

  setFormValues(data: { [key: string]: any }) {
    Object.keys(data).forEach((key) => {
      if (key === "name") {
        let formControl = this.webEnquiryForm.get("customerName");
        if (formControl) {
          formControl.setValue(data[key]);
        }
      } else {
        let formControl = this.webEnquiryForm.get(key);
        if (formControl) {
          formControl.setValue(data[key]);
        }
      }
    });
  }

  toasterSuccess(response: any) {
    this.toasterService.showToaster(response);
    this.formReset();
  }

  toasterWarning(response: any) {
    this.toasterService.showToaster(response);
  }

  createWebEnquiry() {
    const formValues = this.webEnquiryForm.value;
    const createEnquiry = {
      name: formValues.name,
      email: formValues.email,
      countryCode: formValues.countryCode.toString(),
      mobileNumber: formValues.mobileNumber,
      message: formValues.message,
      sourceOfLead: formValues.sourceOfLead,
      enquiryId: this.decryptedData._id,
      companyId: this.decryptedData.companyId,
      region: formValues.region,
      city: formValues.city,
      state: formValues.state,
      product: formValues.product,
      geo: formValues.geo,
      district: formValues.district,
      country: formValues.country
    };
    this.customerEnquiryService
      .createEnquiry(createEnquiry)
      .subscribe(
        (sData) => {
          this.toasterSuccess(sData);
        },
        (error) => {
          console.error("Error:", error);
          this.toasterWarning(error);
        }
      );
  }

  onSubmit() {
    if (this.webEnquiryForm.valid) {
      this.createWebEnquiry();
    }
  }

  formReset() {
    this.webEnquiryForm.reset();
  }

  regionChange() {
    const regionId = this.webEnquiryForm.get('region')?.value;
    if (regionId) {
      this.webEnquiryForm.get('city')?.enable();
      this.getAllStates(regionId);
    }
    else {
      this.webEnquiryForm.get('city')?.disable();
    }
    this.webEnquiryForm.get('state')?.setValue(null)
    this.webEnquiryForm.get('district')?.setValue(null)
    this.webEnquiryForm.get('city')?.setValue(null)
  }

  onSelectdistrict() {
    this.getCitiesById(this.webEnquiryForm.get('district')?.value);
    this.webEnquiryForm.get('city')?.setValue(null);
  }
  onSelectState() {
    this.getAllDistrictById(this.webEnquiryForm.get('state')?.value);
    this.webEnquiryForm.get('district')?.setValue(null);
    this.webEnquiryForm.get('city')?.setValue(null);
  }

  onSelectCountry() {
    this.getAllRegions(this.webEnquiryForm.get('country')?.value);
    this.webEnquiryForm.get('region')?.setValue(null)
    this.webEnquiryForm.get('state')?.setValue(null)
    this.webEnquiryForm.get('district')?.setValue(null)
    this.webEnquiryForm.get('city')?.setValue(null)
  }

  onSelectGeoInLocation() {
    this.getCountryList(this.webEnquiryForm.get('geo')?.value);
    this.webEnquiryForm.get('country')?.setValue(null);
    this.webEnquiryForm.get('state')?.setValue(null);
    this.webEnquiryForm.get('region')?.setValue(null);
    this.webEnquiryForm.get('district')?.setValue(null);
    this.webEnquiryForm.get('city')?.setValue(null);
  }

  getGeoList() {
    this.geoService.getDropdownGeoListWithoutAuth(this.decryptedData.companyId).subscribe((res: any) => {
      if (res && res?.data && res?.data?.geoDropdown) {
        this.geoList = res.data.geoDropdown;
        const geo = this.geoList.find(d => d.geoName === "Asia")
        if (geo) {
          this.webEnquiryForm.get('geo')?.setValue(geo._id);
          let id: any = geo._id
          this.getCountryList(id, true)
        }
      }
    })
  }

  getCountryList(id: string, isSet?: boolean) {
    this.countryService.getDropdownCountryListWithoutAuth({ id, companyId : this.decryptedData.companyId}).subscribe((res: any) => {
      if (res && res.data && res.data.countryDropdown) {
        this.countryList = res.data.countryDropdown;
        if (isSet) {
          const country = this.countryList.find(d => d.name === "India")
          if (country) {
            this.webEnquiryForm.get('country')?.setValue(country._id);
          }
        }
      }
    })
  }
  getAllRegions(id: string) {
    this.regionService.getDropdownRegionListWithoutAuth({ id , companyId : this.decryptedData.companyId}).subscribe((res: any) => {
      if (res && res.data && res.data.regionDropdown) {
        this.regionList = res.data.regionDropdown;
      }
    })
  }

  getAllStates(id: any) {
    this.stateService.getDropdownStateListWithoutAuth({ id , companyId : this.decryptedData.companyId}).subscribe((res: any) => {
      if (res && res.data && res.data.stateDropdown) {
        this.stateList = res.data.stateDropdown;
      }
    })
  } 

  getAllDistrictById(id: string) {
    this.districtService.getDropdownDistrictListWithoutAuth({ id, companyId : this.decryptedData.companyId }).subscribe((res: any) => {
      this.districtList = res.data?.districtDropdown;
    })
  }

  getCitiesById(id: any) {
    this.cityService.getDropdownCityListWithoutAuth({ id , companyId : this.decryptedData.companyId}).subscribe((res: any) => {
      this.cityList = res?.data?.cityDropdown;
    })
  }

  getProductDropDown() {
    this.productService.getDropdownProductListWithoutAuth({ companyId : this.decryptedData.companyId}).subscribe((res: any) => {
      console.log('res#',res)
      if (res && res.data && res.data.productDropdown) {
        this.productList = res.data.productDropdown;
      }
    })
  }
}
