import { Directive, ElementRef, Renderer2, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appResizableColumn]',
})
export class ResizableColumnDirective {
  @Input() minWidth = 50; // Default minimum width
  @Input() maxWidth = 400; // Default maximum width
  @Input() defaultWidth = 100; // Default width

  private resizing = false;
  private initialX = 0;
  private initialWidth = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    // Create a resizer handle for resizing
    const resizer = this.renderer.createElement('div');
    this.renderer.addClass(resizer, 'resizer-handle');
    this.renderer.appendChild(this.el.nativeElement, resizer);

    // Set the default width initially
    this.renderer.setStyle(this.el.nativeElement, 'width', `${this.defaultWidth}px`);

    // Attach mouse events for resizing
    this.renderer.listen(resizer, 'mousedown', this.onMousedown);
    this.renderer.listen(resizer, 'dblclick', this.onDoubleClick); // Double-click event
  }

  onMousedown = (event: MouseEvent) => {
    this.resizing = true;
    this.initialX = event.clientX;
    this.initialWidth = this.el.nativeElement.offsetWidth;
    document.addEventListener('mousemove', this.onMousemove);
    document.addEventListener('mouseup', this.onMouseup);
  };

  onMousemove = (event: MouseEvent) => {
    if (this.resizing) {
      const delta = event.clientX - this.initialX;
      const newWidth = this.initialWidth + delta;

      // Ensure width stays within min and max boundaries
      if (newWidth >= this.minWidth && newWidth <= this.maxWidth) {
        this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
      }
    }
  };


  onDoubleClick = (event: MouseEvent) => {
    // Get current width
    const currentWidth = this.el.nativeElement.offsetWidth;

    // Toggle between min and max widths on double-click
    const newWidth = currentWidth < (this.minWidth + this.maxWidth) / 2
      ? this.maxWidth
      : this.minWidth;

    this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
  };

  private onMouseup = () => {
    this.resizing = false;
    document.removeEventListener('mousemove', this.onMousemove);
    document.removeEventListener('mouseup', this.onMouseup);
  };
}
