import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class DistrictService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}

  getDropdownDistrictList(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/districts/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownDistrictListWithoutAuth(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/districts/no-auth/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getMultiDropdownDistrictList(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/states/districts-list`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAllDistrictList(params:any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/districts/list`
    // if (fiter.companyId) apiUrl = `${apiUrl}&companyId=${fiter.companyId}`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDistrict(id: any) {
    const apiUrl = `${environment.serverEndPoint}/districts/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createDistrict(data: any) {
    const apiUrl = `${environment.serverEndPoint}/districts/create`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateDistrictr(id: any, data:any) {
    const apiUrl = `${environment.serverEndPoint}/districts/` + id;
    return this.httpClient.put(apiUrl,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  exportList(params: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/districts/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
