import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class QueryParamsService {
    constructor() { }
    getQueryParams(queryParams: any) {
        let params = new HttpParams();
        Object.keys(queryParams).forEach((key) => {
          if (queryParams[key] !== null && queryParams[key] !== undefined) {
            params = params.set(key, queryParams[key].toString());
          }
        });
        return params;
    }
}