 <form class="col-auto form-inline search-full" action="#" method="get" (clickOutside)="clickOutside()">
   <div class="form-group">
     <div class="Typeahead Typeahead--twitterUsers">
       <div class="u-posRelative">
         <input class="demo-input Typeahead-input form-control-plaintext w-100" [class.open]="open" [(ngModel)]="text"
           [ngModelOptions]="{ standalone: true }" (keyup)="searchTerm(text)" type="text"
           placeholder="Type to Search .." name="q" title="" autocomplete="off">
         <svg class="search-bg svg-color">
           <use href="assets/svg/icon-sprite.svg#Search"></use>
         </svg>
       </div>
       <div class="Typeahead-menu theme-scrollbar" [class.is-open]="searchResult" *ngIf="menuItems.length"
         id="search-outer" [class.open]="open">
         <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice : 0 : 8">
           <div class="ProfileCard-details">
             <div class="ProfileCard-realName">
               <div class="ProfileCard-avatar">
                 <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
               </div>
               <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{ menuItem?.title }}</a>
             </div>
           </div>
         </div>
       </div>
       <div class="Typeahead-menu is-open" [class.is-open]="searchResultEmpty">
         <div class="tt-dataset tt-dataset-0">
           <div class="EmptyMessage">Opps!! There are no result found.</div>
         </div>
       </div>
     </div>
   </div>
 </form>
