import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessToken = localStorage.getItem(btoa("accessToken"));

    if (accessToken) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(atob(accessToken));
      if (decodedToken) {
        return true;
      }
    }

    // window.alert('You don\'t have permission to view this page');
    this.router.navigate(['auth/login']);
    return false;
  }
}