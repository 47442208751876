import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, finalize, take, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import { paths } from "../const";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  // enc: any = localStorage.getItem(btoa('accessToken'));
  // private token = (localStorage.getItem(btoa('accessToken')))? atob(this.enc) : undefined ;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.warn("AuthInterceptor");
    if (req.url.includes('users/login')) {
      if (!req.headers.has("x-api-key")) {
        req = req.clone({
          headers: req.headers.set("x-api-key", environment.xAPIKey)
        });
      }
      return next.handle(req);
    }
    if(req.url.includes('file-upload') || req.url.includes('using/excel-file')) {
      if (!req.headers.has("x-api-key")) {
        req = req.clone({
          headers: req.headers.set("x-api-key", environment.xAPIKey)
        });
      }
      req = this.addAuthenticationToken(req);
      return next.handle(req);
    }
    if (!req.headers.has("Content-Type")) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json")
      });
    }

    if (!req.headers.has("x-api-key")) {
      req = req.clone({
        headers: req.headers.set("x-api-key", environment.xAPIKey)
      });
    }

    req = this.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
          } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            return this.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this.refreshTokenSubject.next(success);
                return next.handle(this.addAuthenticationToken(req));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => (this.refreshTokenInProgress = false))
            );
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return of("secret token");
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // console.log("addAuthenticationToken", this.token);
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
      // const eny: any = localStorage.getItem(btoa('accessToken'))
      // const token = (localStorage.getItem(btoa('accessToken')))? atob(eny) : undefined ;

      const enc: any = localStorage.getItem(btoa('accessToken'));
      const token = (localStorage.getItem(btoa('accessToken')))? atob(enc) : undefined ;
      if(token) {
        return request.clone({
          headers: request.headers.set(this.AUTH_HEADER, "Bearer " + token)
        });
      }

      return request;
    // If you are calling an outside domain then do not add the token.
        // if (!request.url.match(/www.mydomain.com\//)) {
        //   return request;
        // }
    // return request.clone({
    //   headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.token)
    // });
  }
}