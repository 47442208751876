import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class BranchService {
  constructor(private httpClient: HttpClient, private queryParamsService:QueryParamsService) {}

  getAllBranchList(page: any, limit: any, search?: any) {
    const apiUrl = `${environment.serverEndPoint}/branch/list?page=${page}&limit=${limit}&search=${search}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getBranch(id: any) {
    const apiUrl = `${environment.serverEndPoint}/branch/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createBranch(data: any) {
    const apiUrl = `${environment.serverEndPoint}/branch/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deleteBranch(id: any, data: boolean) {
    const sendData = { isDeleted: data };
    const apiUrl = `${environment.serverEndPoint}/branch/status-update/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateBranch(id: any, data: boolean) {
    const apiUrl = `${environment.serverEndPoint}/branch/update/` + id;
    return this.httpClient.put(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownBaseBranchList() {
    const apiUrl = `${environment.serverEndPoint}/branch/dropdown/list`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  downloadFile(params:any){
    let queryParams: any = new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/branch/list/export`;
    // const apiUrl = `${environment.serverEndPoint}/manage-customer/list/export`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
          "Content-Type": "application/json",
      }
  });
  }
}
