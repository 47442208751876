import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent {
  @Input() totalItems: number = 0;
  @Input() pageSize: number = 10;
  @Input() currentPage: number = 1;
  @Output() pageChange = new EventEmitter<number>();
  goToPageNumber: number;
  getDisplayedRange(): string {
    const currentPage = this.currentPage || 1;
    const pageSize = this.pageSize || 1;
    const totalItems = this.totalItems || 0;
  
    if (totalItems === 0) {
      return "0 - 0"; // When there are no items
    }
  
    const startItem = (currentPage - 1) * pageSize + 1;
    const endItem = Math.min(startItem + pageSize - 1, totalItems);
  
    return `${startItem} - ${endItem}`;
  }

  // Handler for page change
  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  goToPage(pageNumber: number): void {
    const totalPages = Math.ceil(this.totalItems / this.pageSize);

    if (pageNumber >= 1 && pageNumber <= totalPages) {
      this.currentPage = pageNumber;
      this.pageChange.emit(this.currentPage);
    }
  }
}
