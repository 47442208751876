import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitOnComma'
})
export class SplitOnCommaPipe implements PipeTransform {

  transform(value: any, separator: string = ','): string[] {
    console.log(value);
    
    if (!value) {
      return []; // If the value is undefined or null, return an empty array
    }

    if (Array.isArray(value)) {
      return value; // If it's already an array, return as is
    }

    if (typeof value === 'string') {
      // If it's a single string, split by commas or return as a single item array
      return value.includes(',') ? value.split(',').map(v => v.trim()) : [value.trim()];
    }

    return []; // Fallback in case of unexpected data type
  }
}
