import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Allow: Backspace, Tab, Enter, Escape, and arrow keys
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (event.keyCode === 65 && event.ctrlKey === true) ||
      // Allow: Ctrl+C
      (event.keyCode === 67 && event.ctrlKey === true) ||
      // Allow: Ctrl+X
      (event.keyCode === 88 && event.ctrlKey === true) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // Let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress if it isn't
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) &&
      (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent| any): void {
    const pastedData = event?.clipboardData.getData('text/plain');
    if (!this.isNumeric(pastedData)) {
      event.preventDefault();
    }
  }

  private isNumeric(value: string): boolean {
    return /^\d+$/.test(value);
  }

}
