import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class EncryptDecryptService {
    secretKey: string = environment.secretKey;
    iv: string = environment.ivKey;
    encrypted = "";
    decrypted = '';
    
    constructor() {
    //   this.encryptUsingAES256();
    }
    encryptUsingAES256(requestData: any) {
        let _key = CryptoJS.enc.Utf8.parse(this.secretKey);
        let _iv = CryptoJS.enc.Utf8.parse(this.iv);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(requestData), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
        // this.encrypted = encrypted.toString();
        return(encrypted.toString());
    }
    
    decryptUsingAES256(encryptedData: any) {
        let _key = CryptoJS.enc.Utf8.parse(this.secretKey);
        let _iv = CryptoJS.enc.Utf8.parse(this.iv);
        let decrypted = CryptoJS.AES.decrypt(
            encryptedData, _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);
        return(decrypted);
    }
    

    encryptObject(data: any): string {
      const key = CryptoJS.enc.Utf8.parse(this.secretKey);
      const iv = CryptoJS.enc.Utf8.parse(this.iv);
  
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        key,
        {
          iv: iv,
          mode: CryptoJS.mode.CFB, // Change the mode if needed (e.g., CBC, GCM)
          padding: CryptoJS.pad.Pkcs7
        }
      );
  
      return encrypted.toString();
    }
  
    decryptObject(encryptedData: string): any | null {
      const key = CryptoJS.enc.Utf8.parse(this.secretKey);
      const iv = CryptoJS.enc.Utf8.parse(this.iv);
  
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(
          encryptedData,
          key,
          {
            iv: iv,
            mode: CryptoJS.mode.CFB, // Change the mode if needed (e.g., CBC, GCM)
            padding: CryptoJS.pad.Pkcs7
          }
        );
  
        // Convert WordArray to string
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
  
        // Convert string back to object
        const decryptedObject = JSON.parse(decryptedText);
  
        return decryptedObject;
      } catch (error) {
        console.error('Decryption error:', error);
        console.error('Encrypted data:', encryptedData);
        return null; // or handle the error in an appropriate way
      }
    }
}