import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}


  getCurrency(id: any) {
    const apiUrl = `${environment.serverEndPoint}/currency/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  forgotPasswordAPI(data: any) {
    const apiUrl = `${environment.serverEndPoint}/users/forgot-password`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  resetPasswordAPI(data: any) {
    const apiUrl = `${environment.serverEndPoint}/users/reset-password`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  changePasswordAPI(data: any) {
    const apiUrl = `${environment.serverEndPoint}/users/change-admin-password`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

}
