<div class="card">
  <div class="card-block row">
    <div class="col-sm-12 col-lg-12 col-xl-12">
      <div class="table-responsive theme-scrollbar">
        <table class="table table-bordered border-light removeBottomMargin">
          <thead style="background-color: #6c9bcf">
            <tr>
              <th scope="col" class="text-white text-center border border-light">
                S.No
              </th>
              <th *ngFor="let headerList of tableHeadersArray" class="text-white text-center border border-light">
                {{ headerList.displayName }}
              </th>
              <th *ngIf="isPrimarySecondary" scope="col" class="text-white text-center border border-light">
                isPrimary
              </th>
              <th scope="col" class="text-white text-center border border-light">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="tableData.length > 0; else noRecordsBlock">
              <!-- <tr *ngFor="let item of tableData| paginate: { itemsPerPage: itemsPerPage, currentPage: page, totalItems: 28}; let i = index"> -->
              <tr *ngFor="let item of tableData; let i = index">
                <td class="text-center">{{ getSerialNumber(i) }}</td>
                <td class="text-center" *ngFor="let headerList of tableHeadersArray">
                  {{ item[headerList.fieldName] }}

                </td>
                <td class="text-center" *ngIf="isPrimarySecondary">
                  <button *ngIf="tableData[i].isPrimary" type="button" class="btn btn-primary">Primary</button>

                  <button *ngIf="!tableData[i].isPrimary" (click)="toChangeIsPrimary_Secondary(tableData[i])"
                    type="button" class="btn btn-secondary">Secondary</button>
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-center align-items-center" *ngIf="tableData.length > 0">
                    <a (click)="updateTextToCopy(tableData[i])" *ngIf="displayCopy" class="btn-success rounded mx-1">
                      <i class="fas fa-copy cursorStyle" title="Copy"></i>
                    </a>
                    <a [routerLink]="[toViewRoute, tableData[i]?._id]" *ngIf="isSuperAdmin || enableViewAction"
                      class="btn-success rounded mx-1">
                      <i class="fas fa-eye cursorStyle" title="View"></i>
                    </a>
                    <a [routerLink]="[toRoute, tableData[i]?._id]" *ngIf="!isEditable" class="btn-success rounded mx-1"
                    [class.disabled-link]="!canEditItem(tableData[i])"
                    (click)="!canEditItem(tableData[i]) ? $event.preventDefault() : null"
                    >
                      <i class="fas fa-edit cursorStyle" title="Edit"></i>
                    </a>
                    <a (click)="toActiveDelete(item)" *ngIf="!hideAciveDelete" class="btn-success rounded mx-1">
                      <i class="fas fa-trash-alt cursorStyle delete" title="Delete"
                        *ngIf="!tableData[i]?.isDeleted"></i>
                      <i class="fa fa-check cursorStyle" title="Activate" *ngIf="tableData[i]?.isDeleted"></i>
                      <i class="fa fa-check cursorStyle" title="Activate" *ngIf="cityTerritory"></i>
                    </a>
                    <a (click)="toActiveDelete(item)" *ngIf="cityTerritory" class="btn-success rounded mx-1">
                      <i class="fas fa-trash-alt cursorStyle" title="Delete" *ngIf="cityTerritory"></i>
                    </a>
                    <a [routerLink]="['/opportunity/add-Opportunity', tableData[i]?._id]"
                      *ngIf="openOppFromCustomer && tableData[i]?.status?.toUpperCase() == 'APPROVED'"
                      class="btn-success rounded mx-1">
                      <i class="fas fa-plus cursorStyle delete" title="go to Opportunity"></i>
                    </a>
                    <a (click)="convertToLeadPopup(tableData[i])"
                      *ngIf="showConvertToLead && !tableData[i]?.convertedToLead"
                      class="btn-success cursorStyle rounded mx-1 d-inline-block">
                      <span style="white-space: nowrap;">Convert To Lead</span>
                    </a>
                    <a (click)="onRemark(tableData[i])"
                      *ngIf="showConvertToLead && !tableData[i]?.convertedToLead"
                      class="btn-success cursorStyle rounded mx-1">
                      Remarks
                    </a>
                    <a *ngIf="showConvertToLead && tableData[i]?.convertedToLead"
                      class="btn-success cursorStyle rounded mx-1">
                      {{tableData[i]?.leadId}}
                    </a>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-template #noRecordsBlock>
              <tr>
                <td [attr.colspan]="tableHeadersArray.length + 2" class="text-center">
                  <p>No Records Found</p>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>



        <app-custom-pagination [totalItems]="totalItems" [pageSize]="pageSize"
         [currentPage]="page" (pageChange)="getPage($event)">
        </app-custom-pagination>


      </div>
    </div>
  </div>
</div>