import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class StateService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}

  getDropdownStateList(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/states/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownStateListWithoutAuth(params?: any) {
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
      if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/states/no-auth/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAllStateList(params: any) {
      let queryParams: any= new HttpParams();
      if (params && typeof params === 'object' && Object.keys(params).length) {
        queryParams = this.queryParamsService.getQueryParams(params);
      }
    let apiUrl = `${environment.serverEndPoint}/states/list`
    // if (fiter.companyId) apiUrl = `${apiUrl}&companyId=${fiter.companyId}`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  exportStateList(params: any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/states/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getState(id: any) {
    const apiUrl = `${environment.serverEndPoint}/states/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateState(id: any, data:any) {
    const apiUrl = `${environment.serverEndPoint}/states/` + id;
    return this.httpClient.put(apiUrl,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createState(data: any) {
    const apiUrl = `${environment.serverEndPoint}/states/create`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

 
}
