<div class="container-fluid p-0">
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="login-card login-dark">
        <div>
          <div>
            <a class="logo">
              <img
                class="img-fluid for-light"
                height="50"
                width="80"
                src="assets/images/logo/logo.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main" *ngIf="!isResetPassword">
            <form class="theme-form" [formGroup]="forgotPasswordForm">
              <h3>Forgot Password</h3>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input
                  class="form-control"
                  type="text"
                  required=""
                  placeholder="Please Enter Email"
                  formControlName="email"
                />
                <div
                  *ngIf="forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].errors?.['required']"
                  class="text text-danger mt-1"
                >
                  Email is required
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div> -->
                <div class="text-end mt-3">
                  <button
                    class="btn btn-primary btn-block w-100"
                    [disabled]="!forgotPasswordForm.valid"
                    (click)="submit()"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="login-main" *ngIf="isResetPassword">
            <form class="theme-form" [formGroup]="resetPasswordForm">
              <h3>Reset Password</h3>
              <div class="form-group">
                <label class="col-form-label">New Password</label>
                <div class="form-input position-relative">
                <input
                  class="form-control"
                  type="password"
                  required=""
                  placeholder="Please Enter New Password"
                  formControlName="newPassword"
                />
                <div class="show-hide" (click)="showNewPassword()" *ngIf="!newPasswordShow"><span class="show"> </span></div>
                <div class="show-hide" (click)="showNewPassword()" *ngIf="newPasswordShow"><span class="Hide"></span></div>
              </div>
                <!-- <div *ngIf="forgotPasswordForm.controls['email'].touched && forgotPasswordForm.controls['email'].errors?.['required']"
                    class="text text-danger mt-1">
                    Email is required
                  </div> -->
              </div>
              <div class="form-group">
                <label class="col-form-label">Confirm Password</label>
                <div class="form-input position-relative">
                <input
                [type]="show ? 'text' : 'password'"
                  class="form-control"
                  type="password"
                  required=""
                  placeholder="Please Confirm New Password"
                  formControlName="confirmPassword"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"> </span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div
                  *ngIf="forgotPasswordForm.controls['confirmPassword']?.touched && forgotPasswordForm.controls['confirmPassword'].errors?.['required']"
                  class="text text-danger mt-1"
                >
                  Password mismatch
                </div>
              </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls['confirmPassword']?.touched &&
                    resetPasswordForm.controls['confirmPassword'].value !==
                      resetPasswordForm.controls['newPassword'].value
                  "
                  class="text text-danger mt-1"
                >
                  Passwords do not match
                </div>
              </div>
              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div> -->
                <div class="text-end mt-3">
                  <button
                    class="btn btn-primary btn-block w-100"
                    [disabled]="!resetPasswordForm.valid"
                    (click)="resetFormSubmit()"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
