import { Component } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {

  public MassageData: boolean = false;

  Message() {
    this.MassageData = !this.MassageData
  }

}
