import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IFeatureMapping, IFeatureResponse } from '../models/feature-mapping.interface';

@Injectable({providedIn: 'root'})
export class FeaturesService {
    feature$: Observable<IFeatureMapping[]>
    constructor(private httpClient: HttpClient) { }
    getFeatures(type?: string): Observable<IFeatureMapping[]> {
        if (!this.feature$) {
            return this.httpClient.get<IFeatureResponse>(`${environment.serverEndPoint}/features?type=${type}`)
                .pipe(
                    // // tap(res => console.log('REQUEST CALLED:', res)),
                    // shareReplay(1),
                    map(res => res.data.features)
                )
        }
        return this.feature$;
    }
}