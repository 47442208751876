import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}

  getAllProducts(params: any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/product/list`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getProduct(id: any) {
    const apiUrl = `${environment.serverEndPoint}/product/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getFreeProductsByproductId(productId: any) {
    const apiUrl = `${environment.serverEndPoint}/product/free/supply?id=${productId}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createProduct(data: any) {
    const apiUrl = `${environment.serverEndPoint}/product/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateProduct(id: any, data: any) {
    const apiUrl = `${environment.serverEndPoint}/product/update/` + id;
    return this.httpClient.put(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deleteProduct(id: any, data: boolean) {
    const sendData = { isDeleted: data };
    const apiUrl = `${environment.serverEndPoint}/product/status-update/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownProduct() {
    const apiUrl = `${environment.serverEndPoint}/product/dropdown/list`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  getDropdownProductBySearch(search?:any,segment?: string) {
    const apiUrl = `${environment.serverEndPoint}/product/dropdown/list?search=${search}&segment=${segment}`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
  async getDropdownProductAsync(search?:any,segment?: string): Promise<any> {
    try {
      const apiUrl = `${environment.serverEndPoint}/product/dropdown/list?search=${search}&segment=${segment}`;
      const response = await this.httpClient.get(apiUrl, {headers: {"Content-Type": "application/json"}}).toPromise();
      return response;
    } catch (error) {
      throw error; 
    }
  }

  searchProduct(name:string) {
    const apiUrl = `${environment.serverEndPoint}/product/search?productName=` + name;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  getProductByCategoryAndSegmentGroup() {
    const apiUrl = `${environment.serverEndPoint}/product/category/segment-group/drop-down`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  exportList(params: any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/product/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownProductListWithoutAuth(params?: any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/product/no-auth/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
