import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private httpClient: HttpClient,  private queryParamsService: QueryParamsService) {}

  getAllCustomerList(params?:any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/manage-customer/list`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getCustomer(id: any) {
    const apiUrl = `${environment.serverEndPoint}/manage-customer/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createCustomer(data: any) {
    const apiUrl = `${environment.serverEndPoint}/manage-customer/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateCustomerStatus(id: any, data: any) {
    const apiUrl = `${environment.serverEndPoint}/manage-customer/approval/status-update/` + id;
    return this.httpClient.put(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateCustomer(id: any, data: any) {
    const apiUrl = `${environment.serverEndPoint}/manage-customer/update/` + id;
    return this.httpClient.put(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  
  deleteCustomer(id: any, data: boolean) {
    const sendData = { isDeleted: data };
    const apiUrl = `${environment.serverEndPoint}/manage-customer/status-update/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownCustomere(status?: string) {
    let apiUrl = `${environment.serverEndPoint}/manage-customer/dropdown/list`;
    if(status){
      apiUrl = `${apiUrl}?status=ALL`
    }
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  getDropdownCity() {
    const apiUrl = `${environment.serverEndPoint}/city/dropdown`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  sampleFileUpload(){
    const apiUrl = `${environment.serverEndPoint}/manage-customer/export-excel/sample-file`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }

  bulkUpload(file: any){
    const apiUrl = `${environment.serverEndPoint}/manage-customer/bulk-upload/using/excel-file`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.httpClient.post<any>(apiUrl, file, { headers});
  }


  downloadFile(params:any, status:string){
    let queryParams: any = new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/manage-customer/list/export?status=${status}`;
    // const apiUrl = `${environment.serverEndPoint}/manage-customer/list/export`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
          "Content-Type": "application/json",
      }
  });
  }
}
