import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, map, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";
import { CompanyDetails } from "../../data/component/models-interfaces/manage-company/company-interface";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class CountryService {
  constructor(private httpClient: HttpClient, private queryParamsService: QueryParamsService) {}


  getCountry(id: any) {
    const apiUrl = `${environment.serverEndPoint}/country/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  exportList(params: any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/country/list/export`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateAndGetAllGeo(id: any, data:any) {
    const apiUrl = `${environment.serverEndPoint}/country/` + id;
    return this.httpClient.put(apiUrl,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createCountry(data: any) {
    const apiUrl = `${environment.serverEndPoint}/country/create`;
    return this.httpClient.post<CompanyDetails>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownCountryList(params?: any) {
    // queryParamsService
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/country/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getDropdownCountryListWithoutAuth(params?: any) {
    // queryParamsService
    let queryParams: any= new HttpParams();
    // if (Object.keys(params).length) {
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/country/no-auth/dropdown`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }


  getAllCountryList(params: any) {
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    // let apiUrl = `${environment.serverEndPoint}/country/list?page=${page}&limit=${limit}&search=${fiter?.countryName || ""}&geoId=${fiter?.geoId || ""}`
    let apiUrl = `${environment.serverEndPoint}/country/list`
    // if (fiter.companyId) apiUrl = `${apiUrl}&companyId=${fiter.companyId}`;
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }


  // https://api-stg.developmentinsideout.com/country/dropdown?search=&id=6579ce5de48af260f3549aa7
}
