<div [ngClass]="navService.isDisplay ? 'page-header close_icon':'page-header'" [class.sidebar-default]="navService.isShow == true">
  <div class="header-wrapper row m-0">
    <div class="logo-wrapper">
      <a [routerLink]="'/dashboard/admin-dashboard'"><img class="img-fluid" src="assets/images/logo/logo.png" alt=""></a>
    </div>
    <svg class="stroke-icon toggle-sidebar" (click)="openMenu()">
      <use class="status_toggle middle sidebar-toggle" href="assets/svg/icon-sprite.svg#Grid"> </use>
    </svg>
    <!-- <div class="col-auto p-0">
      <app-search></app-search>
    </div> -->
    <div class="nav-right col-auto pull-right right-header p-0 ms-auto">
      <ul class="nav-menus" [class.open]="open">
        <!-- <li class="serchinput">
          <div class="serchbox" (click)="openSearch()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#Search"></use>
            </svg>
          </div>
          <div class="form-group search-form" [ngClass]="open ? 'open' : ''">
            <input class="demo-input Typeahead-input form-control-plaintext w-100" [class.open]="open"
              [(ngModel)]="text" [ngModelOptions]="{ standalone: true }" (keyup)="searchTerm(text)" type="text"
              placeholder="Type to Search .." name="q" title="" autocomplete="off">
          </div>
        </li> -->
        <li class="onhover-dropdown" style="visibility: hidden;">
          <!-- <app-notification></app-notification> -->
        </li>
        <li class="onhover-dropdown" style="visibility: hidden;">
          <!-- <app-bookmark></app-bookmark> -->
        </li>
        <li class="onhover-dropdown message-box" style="visibility: hidden;">
          <!-- <app-message></app-message> -->
        </li>
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <li>
          <app-theme></app-theme>
        </li>
        <!-- <li class="language-nav" (click)="languageToggle()">
          <app-language></app-language>
        </li> -->
        <li class="profile-nav onhover-dropdown pe-0 py-0">
          <app-profile></app-profile>
        </li>
        <div class="Typeahead-menu theme-scrollbar" [class.is-open]="searchResult" *ngIf="menuItems.length"
          id="search-outer" [class.open]="open" (clickOutside)="clickOutside()">
          <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice : 0 : 8">
            <div class="ProfileCard-details">
              <div class="ProfileCard-realName">
                <div class="ProfileCard-avatar">
                  <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                </div>
                <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{ menuItem?.title }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="Typeahead-menu is-open" [class.is-open]="searchResultEmpty">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">Opps!! There are no result found.</div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</div>
