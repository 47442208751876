import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryParamsService } from '../common/queryParams.service';

@Injectable({
  providedIn: 'root'
})
export class PlanVistService {

  constructor(private httpClient: HttpClient, private queryParamsService:QueryParamsService) {}

  getAllPlanVisits(page: any, limit: any, filter?:any) {
    let url = `${environment.serverEndPoint}/plan-visit/list?page=${page}&limit=${limit}`;
    if(filter.customer){
      url = `${url}&customer=${filter.customer}`
    }
    if(filter.startDate){
      url = `${url}&startDate=${filter.startDate}`
    }
    if(filter.endDate){
      url = `${url}&endDate=${filter.endDate}`
    }
    if(filter.createdBy){
      url = `${url}&createdBy=${filter.createdBy}`
    }
    return this.httpClient.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getPlanVisit(id: any) {
    const getAPI = `${environment.serverEndPoint}/plan-visit/`+id;
    return this.httpClient.get(getAPI, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updatePlanVisit(id: any, data:any) {
    const getAPI = `${environment.serverEndPoint}/plan-visit/update/`+id;
    return this.httpClient.put(getAPI,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  feedbackUpdateForVisit(id: any, data:any) {
    const getAPI = `${environment.serverEndPoint}/plan-visit/status-update/`+id;
    return this.httpClient.put(getAPI,data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  createPlanVisit(data: any) {
    const getAPI = `${environment.serverEndPoint}/plan-visit/create`;
    return this.httpClient.post<any>(getAPI, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  deletePlanVisit(id:any, data:boolean){
    const sendData={ isDeleted:data }
    const apiUrl = `${environment.serverEndPoint}/plan-visit/status-update/` + id;
    return this.httpClient.put(apiUrl, sendData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getReport(params?:any){
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/plan-visit/report/list`;
    return this.httpClient.get(apiUrl, {
        params: queryParams,
        headers: {
            "Content-Type": "application/json",
        }
    });
  }

  getReportExport(params?:any){
    let queryParams: any= new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const apiUrl = `${environment.serverEndPoint}/plan-visit/excel/report`;
    return this.httpClient.get(apiUrl, {
        params: queryParams,
        headers: {
            "Content-Type": "application/json",
        }
    });
  }

  sampleFileUpload(){
    const apiUrl = `${environment.serverEndPoint}/plan-visit/excel/report?user=65c87a2145fdf039ff03dda5&startTime=2024-03-01T18:30:00.000Z&endTime=2024-03-10T18:30:00.000Z`;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
  
}
