import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observer } from 'rxjs';
import { SkanrayToastService } from 'src/app/shared/services/common/skanray-toast.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  id: string;
  dataLoaded = false;
  isReadOnlyView = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private toasterService: SkanrayToastService
  ) {

  }


  ngOnInit(){
    this.intializeForm();
  }

  intializeForm(){
    this.changePasswordForm = this.fb.group ({
      newPassword: [null, Validators.required],
      oldPassword: [null, Validators.required],
      confirmNewPassword: [null, Validators.required]
    })
  }

  onSubmit(){
    const oldPassword = this.changePasswordForm?.get("oldPassword")?.value;
    const newPassword = this.changePasswordForm?.get("newPassword")?.value;
    const confirmPassword =
      this.changePasswordForm?.get("confirmNewPassword")?.value;
    if (newPassword === confirmPassword && oldPassword != newPassword ) {
      this.changePassword();
      console.log("password changed successfull");
    }
  }

  toasterWarning(response: any) {
    this.toasterService.showToaster(response);
  }

  changePassword(){
    this.changePasswordForm.get('confirmNewPassword')?.disable();
    this.loginService.changePasswordAPI(this.changePasswordForm.value).subscribe({
      
      next: (response: any) => {
        if (response?.status === 201) {
          this.toasterWarning(response);
          this.router.navigate(["/auth/login"]);
        }
        console.log("Data sent successfully:", response);
      },
      error: (error: any) => {
        this.changePasswordForm.get('confirmNewPassword')?.enable();
        this.toasterWarning(error);
        console.error("Error sending data:", error);
      },
      complete: () => {
        console.log("Observable completed");
      },
    } as Observer<any>)
  }
}
