import { Component } from '@angular/core';
import { IFeatureMapping } from './shared/models/feature-mapping.interface';
import { RoleMappingHelperService } from './shared/services/role-mapping/role-mapping-helper.service';
import { FeatureDataJSON } from './shared/data/component/features-rolemapping/feature';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FeaturesService } from './shared/services/features.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './shared/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  features$: Subscription;

  constructor(private rolemappingHelper: RoleMappingHelperService,
              private featuresService: FeaturesService, 
              private authService: AuthenticationService) {
    
  }
  ngOnInit() {
    // this.setFeatures(FeatureDataJSON);
    const storedEncodedToken = localStorage.getItem(btoa("accessToken"));
    if (storedEncodedToken) {
      this.getFeatures();
      this.setAccessCodes(storedEncodedToken);
      this.checkUserDetails();
    }
  }

  checkUserDetails() {
    const storedEncodedUsername = localStorage.getItem(btoa("username"));
    const storedEncodedRole = localStorage.getItem(btoa("role"));
    if (storedEncodedUsername && storedEncodedRole) {
      const username:any = atob(storedEncodedUsername);
      const role:any = atob(storedEncodedRole);
      this.authService.setUserDetails(username, role);
    }
  }

  private setAccessCodes(storedEncodedToken: string) {
    const helper = new JwtHelperService();
    if (storedEncodedToken) {
      const accessToken:any = atob(storedEncodedToken);
      const decodedToken = helper.decodeToken(accessToken);
      // console.log('decodedToken ', decodedToken);
      this.rolemappingHelper.ACCESS_CODES = decodedToken?.accessCodes;
    }
  }

  getFeatures() {
    this.features$ = this.featuresService.getFeatures()
    .subscribe((res: IFeatureMapping[]) => {
      this.setFeatures(res);
    })
  }
  private setFeatures(features: IFeatureMapping[]) {
    this.rolemappingHelper.FEATURES = features;
  }
  
}
